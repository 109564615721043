
.main-boards {
    padding: 50px 0;
    &__list {
        margin: -16px;
        > [class*="col"] {
            padding: 16px;
        }
    }
}
@media (min-width: 768px) {
    .main-boards {
        padding: 100px 0;
        &__list {
            margin: -20px;
            > [class*="col"] {
                padding: 20px;
            }
        }
    }
}
