
.main-partners {
    padding: 24px 0;
    border-top: 1px solid #f5f5f5;
    .container {
        position: relative;
    }
    .swiper-prev {
        left: -50px;
        transform: translate(-100%, -50%);
    }
    .swiper-next {
        right: -50px;
        transform: translate(100%, -50%);
    }
}
