
.main-news {
    padding: 60px 0 40px;
}
.v-card {
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 0 24px 0 rgba(133, 149, 168, 0.25) !important;
    &__subtitle,
    &__title,
    &__actions {
        padding-left: 24px;
        padding-right: 24px;
    }
    &__subtitle {
        color: var(--v-primary-base) !important;
        padding-bottom: 8px;
        font-size: var(--title-font-size-xs);
    }
    &__title {
        padding-top: 0;
        font-size: var(--title-font-size);
        line-height: 1.35;
        font-weight: 500;
        word-break: keep-all;
    }
}
@media (min-width: 1024px) {
    .main-news {
        padding: 120px 0 80px;
    }
}
