
// main-visual
.main-visual {
    .swiper-control {
        height: 100%;
        .swiper-prev,
        .swiper-next {
            aspect-ratio: 35 / 65;
            width: 16px;
            height: auto;
            background-repeat: no-repeat;
            background-position: top;
            background-color: transparent;
            background-size: 100%;
        }
        .swiper-prev {
            background-image: url(/images/main/visual/swiper-prev.svg);
        }
        .swiper-next {
            background-image: url(/images/main/visual/swiper-next.svg);
        }
        ::v-deep(.swiper-pagination) {
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            .swiper-pagination-bullet {
                background-color: #fff;
                opacity: 0.4;
                &-active {
                    opacity: 1;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    // main-visual
    .main-visual {
        .swiper-control {
            .swiper-prev,
            .swiper-next {
                width: 35px;
            }
            ::v-deep(.swiper-pagination) {
                bottom: 40px;
            }
        }
    }
}
