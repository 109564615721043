
.main-transparency {
    text-align: center;
    .v-btn {
        border-width: 2px;
        padding: 0 18px;
    }
}
@media (min-width: 768px) {
    .main-transparency {
        .v-btn {
            padding: 0 24px;
        }
    }
}

@media (min-width: 1200px) {
    .main-transparency {
        text-align: start;
    }
}
