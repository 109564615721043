<template>
    <client-page>
        <!-- 메인 비주얼 -->
        <main-visual />
        <!-- 후원하기 폼 -->
        <main-support />
        <!-- 사업소식 -->
        <main-news />
        <!-- 이미지 배너 -->
        <main-image-banner />
        <main-transparency />
        <main-influence />
        <!-- 공지사항, 언론보도, 뉴스레터 신청하기 -->
        <main-boards />
        <main-partners />
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import api from "@/api";

import MainVisual from "@/components/client/main/main-visual.vue";
import MainSupport from "@/components/client/main/main-support.vue";
import MainNews from '@/components/client/main/main-news.vue';
import MainImageBanner from '@/components/client/main/main-image-banner.vue';
import MainTransparency from '@/components/client/main/main-transparency.vue';
import MainInfluence from '@/components/client/main/main-influence.vue';
import MainBoards from '@/components/client/main/main-boards.vue';
import MainPartners from '@/components/client/main/main-partners.vue';

export default {
    components: {
        ClientPage,
        MainVisual,
        MainSupport,
        MainNews,
        MainImageBanner,
        MainTransparency,
        MainInfluence,
        MainBoards,
        MainPartners,
    },
    data() {
        return {};
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            var noticeList = await api.v1.boards.gets({
                headers: {
                    limit: 4,
                },
                params: { code: "notice" },
            });
            this.notice = noticeList.boards;

            var campaignList = await api.v1.supports.donations.gets({
                headers: {
                    limit: 3,
                },
                params: { code: "koCampaign" },
            });
            this.campaign = campaignList.supports;
            console.log(campaignList, "dd");
        },
        search(_board) {
            this.$router.push(`community/${_board}`);
        },
    },
};
</script>

<style scoped>
.visual {
    position: relative;
}
.visual .swiper-slide {
    width: 100%;
    height: 100vh;
}
.swiper-slide__inner {
    width: 100%;
    padding-top: calc(var(--header-head) + var(--header-body));
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.visual__con {
    width: 100%;
    padding: 0 12px;
    max-width: calc(var(--container) + 24px);
    position: relative;
    text-align: center;
    margin-bottom: 350px;
    z-index: 1;
}
.swiper-slide__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    z-index: 0;
}

.text-ani-wrap {
    margin-top: -4px;
}
.text-ani {
    padding-top: 4px;
}

.visual-slide-control {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: var(--header-body) 12px 30px;
    max-width: calc(var(--container) + 24px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    display: none;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .visual__con {
        text-align: left;
        margin-bottom: inherit;
    }
    .visual-slide-control {
        padding: var(--header-body) 12px 0;
        align-items: center;
        justify-content: flex-start;
    }
    .text-ani-wrap {
        margin-bottom: 42px;
    }
    .visual-pagination {
        margin-top: 130px;
    }
}
@media (min-width: 1024px) {
    .text-ani-wrap {
        margin-bottom: 52px;
    }
    .visual-pagination {
        margin-top: 174px;
    }
}
@media (min-width: 1200px) {
}
</style>
