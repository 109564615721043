
.main-links {
    .links-list {
        display: flex;
        flex-wrap: wrap;
        margin: -8px auto;
        max-width: calc(136px * 2 + 8px * 2);
        > li {
            flex-basis: 50%;
            padding: 8px;

            .v-card {
                max-width: 136px;
                margin: 0 auto;
            }
        }
    }
}
@media (min-width: 768px) {
    .main-links {
        .section-title-wrap {
            margin-bottom: 60px;
        }
        .links-list {
            max-width: calc(136px * 4 + 8px * 2);
            > li {
                flex-basis: 25%;
            }
        }
    }
}
@media (min-width: 1200px) {
    .main-links {
        .links-list {
            max-width: initial;
            margin: -8px;
            > li {
                flex-basis: calc(100% / 8);
                padding: 8px;
            }
        }
    }
}
